//**************************************************//
//* STYLING FOR MULTI-SELECT                       *//
//**************************************************//

.VirtualizedSelectOption {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    cursor: pointer;
}
.VirtualizedSelectFocusedOption {
    background-color: rgba(0, 126, 255, 0.1);
}
.VirtualizedSelectDisabledOption {
    opacity: 0.5;
}
.VirtualizedSelectSelectedOption {
    font-weight: bold;
}

.Select--multi .Select-value {
    background-color: $super-light-gray;
    color: $dark-gray;
    border: 1px solid $input-border;
    margin-top: 4px;
}

.Select--multi .Select-value-icon {
    border-right: 1px solid $light-gray;
    font-size: 1.6em;
    padding: 1px 4px 1px 4px;
    line-height: 18px;
    float: right;
}

.Select-value-label {
    font-size: 14px;
}

.Select-value {
    padding-top: 1px;
    height: 100%;
}

.Select-control {
    height: 32px;
    border-radius: 4px;

    .Select-placeholder {
        line-height: 34px !important;
        color: #999;
        padding-left: 12px !important;
    }

    .Select-value {
        line-height: 34px !important;
        padding-left: 12px !important;

        .Select-value-label {
            line-height: 34px !important;
        }
    }

    .Select-input {
        height: 32px !important;
    }
}

.is-disabled {
    .Select-control {
        background-color: #eeeeee !important;
        cursor: not-allowed;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
}

.Select-menu-outer {
    z-index: 99 !important;
}

.Select-clear-zone {
    padding-bottom: 4px;
}

.Select-menu {
    .VirtualizedSelectOption {
        padding: 8px 12px !important;
        &:hover {
            background-color: rgba(0, 126, 255, 0.1);
        }
    }
    .VirtualizedSelectFocusedOption {
        background-color: transparent;
    }
    .Select-option {
        padding: 8px 12px !important;
    }
}

.Select-input {
    height: 32px;
}

.Select--multi .Select-value-label {
    padding: 0 7px 0 4px;
}

.Select--multi {
    .Select-clear {
        visibility: hidden;
        width: 42px;
    }
    .Select-clear:after {
        visibility: visible;
        content: 'Clear';
        color: $motili-light-blue;
        font-size: 14px;
    }
}

.is-focused:not(.is-open) > .Select-control {
    border-color: $motili-light-blue;
    outline: 0;
    -webkit-box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba($motili-light-blue, 0.6);
    box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba($motili-light-blue, 0.6);
}

//**************************************************//
//* STYLING FOR CHECKBOXES                         *//
//**************************************************//

.motili-checkbox {
    display: flex;

    label {
        display: inline;
    }

    input {
        display: none;
    }

    input + label {
        background-color: $white;
        border: 1px solid $medium-gray;
        box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        padding: 9px;
        border-radius: 3px;
        display: inline-block;
        position: relative;
    }

    input + label:active,
    input:checked + label:active {
        box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    input:checked + label {
        background-color: $white;
        border: 1px solid $medium-gray;
        box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
            inset 15px 10px -12px rgba(255, 255, 255, 0.1);
        color: #99a1a7;
    }

    input:checked + label:after {
        content: '\f00c';
        font-family: FontAwesome;
        font-size: 13px;
        position: absolute;
        top: 0px;
        left: 3px;
        color: $motili-light-blue;
    }

    input:disabled + label {
        background-color: #eeeeee;
        color: #eeeeee;
        cursor: not-allowed;
    }

    span {
        margin-left: 10px;
    }

    input:disabled ~ span {
        color: #818181;
    }
    //input:checked + label:after {
    //  content: '\2713';
    //  font-size: 17px;
    //  position: absolute;
    //  top: -2px;
    //  left: 5px;
    //  color: #57c1e9;
    //  transform: rotate(10deg);
    //}
}

.motili-checkbox-noLabel {
    input:checked + label:after {
        top: -3px;
    }
}

//**************************************************//
//* STYLING FOR RADIO BUTTONS                      *//
//**************************************************//

.mo-radio {
    position: relative;

    label {
        display: inline;
    }
    input {
        display: none;
    }
    input + label {
        -webkit-appearance: none;
        background-color: $white;
        border: 2px solid #cacece;
        box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 8px;
        border-radius: 50px;
        display: inline-block;
        position: relative;
    }
    input:checked + label:after {
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius: 50px;
        position: absolute;
        top: 3px;
        background: $motili-light-blue;
        box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
        text-shadow: none;
        left: 3px;
        font-size: 32px;
    }
    input:checked + label {
        background-color: $white;
        color: #99a1a7;
        border: 2px solid $medium-gray;
        box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
            inset 15px 10px -12px rgba(255, 255, 255, 0.1),
            inset 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    input + label:active,
    input:checked + label:active {
        box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }
    input:disabled + label {
        background-color: #eeeeee;
        color: #eeeeee;
        cursor: not-allowed;
    }
    span {
        position: absolute;
        left: 25px;
        top: 1px;
    }
    input:disabled ~ span {
        color: #eeeeee;
    }
}

//**************************************************//
//* STYLING FOR INPUT FORMS                        *//
//**************************************************//

.input-group .form-group {
    &:not(:first-child):not(:last-child) {
        .form-select-overrides {
            .Select-control {
                border-radius: 0;
            }
        }
    }
    .form-select-overrides {
        .Select.is-focused:not(.is-open) > .Select-control {
            border-color: #ccc;
            box-shadow: none;
        }
    }
}

.form-group {
    position: relative;
    margin: 0;

    .dropdown-toggle {
        /* Fixes display issue with bootstrap-sass stylesheet and dropdown inputs */
        line-height: 1.45;
    }

    .input-group-btn > .btn {
        /* Fixes display issue with bootstrap-sass stylesheet and dropdown inputs */
        line-height: 1.45;
    }

    .dropdown + .form-group > .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-message {
        position: absolute;
        top: 5px;
        right: 10px;
        font-style: italic;
    }

    .input-optional {
        @extend .input-message;
        color: $medium-gray;
    }

    .input-required {
        @extend .input-message;
        color: $red;
    }

    .input-optional-no-label {
        @extend .input-optional;
        top: -19px;
    }

    .input-required-no-label {
        @extend .input-required;
        top: -19px;
    }
}

.form-control-feedback {
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-form-control-feedback {
    .has-error {
        .form-control-feedback {
            display: none;
        }
        .Select-arrow {
            border-color: #e63232 transparent transparent !important;
        }
        .Select-value-label {
            color: #e63232 !important;
        }
    }
}

// VALIDATION STYLING
.has-error {
    .form-control,
    .styled-select__control {
        border-color: $red !important;
        background-color: white;
    }
    .control-label {
        color: $red;
    }
    .form-control-feedback {
        color: $red;
    }
    .help-block {
        color: $red;
    }
}

.has-success {
    .form-control {
        border-color: #ccc;
    }
    .control-label {
        color: black;
    }
    .form-control-feedback {
        color: $green;
    }
    .help-block {
        color: $green;
    }
}

.has-warning {
    .form-control {
        border-color: $yellow;
    }
    .control-label {
        color: $yellow;
    }
    .form-control-feedback {
        color: $yellow;
    }
    .help-block {
        color: $yellow;
    }
}

.email-form {
    position: relative;
    .not-valid-description {
        position: absolute;
        top: 0px;
        right: 2px;
        font-style: italic;
    }
    .not-valid-symbol {
        position: absolute;
        top: 35px;
        right: 12px;
        &:after {
            content: '\2757';
        }
    }
    .not-valid-symbol-no-label {
        position: absolute;
        top: 10px;
        right: 12px;
        &:after {
            content: '\2757';
        }
    }
    .circle {
        width: 22px;
        height: 22px;
        background: $green;
        border-radius: 50px;
        position: absolute;
        top: 32px;
        right: 9px;
    }
    .circle-no-label {
        width: 22px;
        height: 22px;
        background: $green;
        border-radius: 50px;
        position: absolute;
        top: 10px;
        right: 9px;
    }
    i {
        color: $white;
        font-size: 12px;
        position: absolute;
        top: 4px;
        left: 4px;
    }
}

// TODO text input should be base, email-form should extend from text-input (email-form should also be renamed)

.text-input {
    position: relative;
    .not-valid-description {
        position: absolute;
        top: 0px;
        right: 2px;
        font-style: italic;
    }
    .not-valid-symbol {
        position: absolute;
        top: 35px;
        right: 12px;
        &:after {
            content: '\2757';
        }
    }
    .not-valid-symbol-no-label {
        position: absolute;
        top: 10px;
        right: 12px;
        &:after {
            content: '\2757';
        }
    }
    .circle {
        width: 22px;
        height: 22px;
        background: $green;
        border-radius: 50px;
        position: absolute;
        top: 32px;
        right: 9px;
    }
    .circle-no-label {
        width: 22px;
        height: 22px;
        background: $green;
        border-radius: 50px;
        position: absolute;
        top: 10px;
        right: 9px;
    }
    i {
        color: $white;
        font-size: 12px;
        position: absolute;
        top: 4px;
        left: 4px;
    }
}

//**************************************************//
//* STYLING FOR TYPEAHEAD INPUT GROUPS             *//
//**************************************************//

.type-ahead {
    display: flex;
    .dropdown-menu {
        margin: 0;
        width: 100%;
        .highlighted {
            // same as default bootstrap for .dropdown-menu > li > a:hover
            text-decoration: none;
            color: #262626;
            background-color: #f5f5f5;
        }
    }
}
.type-ahead-input-group {
    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.form-container {
    background-color: $super-light-gray;
    box-shadow:
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 2.5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

//**************************************************//
//* STYLING FOR AIRBNB DATEPICKER ???????????????? *//
//**************************************************//;.
.motili-table-deprecated input::placeholder {
    color: #555555;
}
.rc-time-picker {
    width: 100%;
}
